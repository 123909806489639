import React from "react";
import logo from "../assets/ClusterCode Logo Transpanent BG.png";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer className="w-full bg-gradient-to-r mt-20 bg-[#2b2b2c] text-gray-300 py-10">
      <div className="container mx-auto px-5 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Logo and Description */}
        <div className="flex flex-col items-center md:items-start">
          <img
            src={logo}
            className="w-10 mb-3"
            alt="ClusterCode Logo"
            data-aos="zoom-in"
            data-aos-duration="1000"
          />
          <h2
            className="text-lg font-bold"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            ClusterCode
          </h2>
          <p
            className="text-sm text-gray-400 mt-2 text-center md:text-left"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            Delivering innovative web, mobile, and desktop solutions tailored to
            your needs.
          </p>
        </div>

        {/* Quick Links */}
        <div
          className="flex flex-col items-center md:items-start"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <h3 className="text-md font-semibold mb-3">Quick Links</h3>
          <div className="flex flex-col gap-2">
            <Link
              spy={true}
              smooth={true}
              to="Home"
              className="cursor-pointer hover:text-white transition duration-200"
            >
              Home
            </Link>
            <Link
              spy={true}
              smooth={true}
              to="Products"
              className="cursor-pointer hover:text-white transition duration-200"
            >
              Products
            </Link>
            <Link
              spy={true}
              smooth={true}
              to="About"
              className="cursor-pointer hover:text-white transition duration-200"
            >
              About
            </Link>
            <Link
              spy={true}
              smooth={true}
              to="Contact"
              className="cursor-pointer hover:text-white transition duration-200"
            >
              Contact
            </Link>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="flex flex-col items-center md:items-start">
          <h3
            className="text-md font-semibold mb-3"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            Follow Us
          </h3>
          <div className="flex gap-5 text-2xl">
            <FontAwesomeIcon
              icon={faFacebookSquare}
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="cursor-pointer hover:text-blue-500 transition duration-200"
              onClick={() =>
                window.open("https://www.facebook.com/clustercodecc", "_blank")
              }
            />
            <FontAwesomeIcon
              icon={faInstagramSquare}
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="cursor-pointer hover:text-pink-500 transition duration-200"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/clustercodecc?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
                  "_blank"
                )
              }
            />
            <FontAwesomeIcon
              icon={faWhatsappSquare}
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="cursor-pointer hover:text-green-500 transition duration-200"
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=94772064930&text=Hi",
                  "_blank"
                )
              }
            />
            <FontAwesomeIcon
              data-aos="zoom-in"
              data-aos-duration="1000"
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/clustercodecc/",
                  "_blank"
                );
              }}
              className="cursor-pointer hover:text-blue-500 transition duration-200"
              icon={faLinkedin}
            />
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-700 mt-8 pt-5 text-center text-sm">
        <p data-aos="zoom-in" data-aos-duration="1000">
          © 2024 ClusterCode Software Solutions. All rights reserved.
        </p>
        <div className="flex justify-center gap-4 mt-2">
          <span
            data-aos="zoom-in"
            data-aos-duration="1000"
            className="cursor-pointer hover:text-white transition duration-200"
          >
            Privacy Policy
          </span>
          <span
            data-aos="zoom-in"
            data-aos-duration="1000"
            className="cursor-pointer hover:text-white transition duration-200"
          >
            Terms & Conditions
          </span>
        </div>
      </div>
    </footer>
  );
}
