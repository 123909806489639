import React, { useState } from "react";
import logo from "../assets/ClusterCode Logo Transpanent BG.png";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-scroll";
import { SwipeableDrawer } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {["Home","Products", "About", "Contact"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              {/* <ListItemText primary={text} /> */}
              <Link
                spy={true}
                smooth={true}
                to={text}
                className="text-white w-full font-semibold text-md poppins cursor-pointer uppercase"
                onClick={() => {
                  setOpen(false);
                }}
              >
                {text}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div className="fixed bg-[#1e1e1e] w-full h-[70px] flex items-center justify-center z-20">
      <div className="flex w-full lg:w-5/6 md:w-5/6">
        <a
          href="#"
          className="flex items-center gap-3 w-2/3 lg:w-1/3 md:w-2/4 lg:justify-start md:justify-start justify-center cursor-pointer"
        >
          <img src={logo} className="h-[40px]" alt="cluster code logo" />
          <div className="border-[#cd9705] border h-full"></div>
          <span className="text-[#cd9705] font-bold protestRevolution lg:text-3xl md:text-3xl text-2xl">
            Cluster Code
          </span>
        </a>
        <div className="lg:block md:block hidden w-2/3">
          <div className="w-full justify-end flex items-center lg:gap-16 md:gap-10 h-full">
            <Link
              spy={true}
              smooth={true}
              to="Home"
              className="text-white hover:font-bold text-md poppins cursor-pointer uppercase"
            >
              Home
            </Link>
            <Link
              spy={true}
              smooth={true}
              to="Products"
              className="text-white hover:font-bold text-md poppins cursor-pointer uppercase"
            >
              Services
            </Link>
            <Link
              spy={true}
              smooth={true}
              to="About"
              className="text-white hover:font-bold text-md poppins cursor-pointer uppercase"
            >
              About
            </Link>
            <Link
              spy={true}
              smooth={true}
              to="Contact"
              className="text-white hover:font-bold text-md poppins cursor-pointer uppercase"
            >
              Contact
            </Link>
            {/* <button
            className="text-white font-semibold text-md poppins bg-[#cd9705] px-3 py-1 rounded-md "
            onClick={toggleDrawer(true)}
          >
            Sign In
          </button> */}
          </div>
        </div>

        <div className="w-1/3 flex justify-center items-center lg:hidden md:hidden block">
          <FontAwesomeIcon
            icon={faBars}
            className="text-3xl text-[#cd9705] cursor-pointer"
            onClick={toggleDrawer(true)}
          />
        </div>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        className="text-white"
        sx={{ "& .MuiDrawer-paper": { backgroundColor: "#2b2b2c" } }}
      >
        {DrawerList}
      </SwipeableDrawer>
    </div>
  );
}
